<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Recordings
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <div class="player-container" v-if="classLoader == false">
          <vue3-video-player @play="your_method" :src="src"></vue3-video-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService.js";
import errorLog from "@/errorLog";

export default {
  name: "RecordingVideo",
  data() {
    return {
      src: "",
      classLoader: true,
      errorMessage: "No recordings found "
    };
  },
  created() {
    this.getRecordings(0);
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    your_method() {
      console.log("video playing");
    },
    async getRecordings(page) {
      this.classLoader = true;
      const data = {
        page: page,
        schedule_id: this.$route.params.id
      };
      await ProgramService.getRecording(data)
        .then(result => {
          if (result.data.status == "SUCCESS") {
            this.classLoader = false;
            this.src = result.data.recording.file_url;
          } else {
            this.classLoader = false;
            this.errorMessage = result.data.message;
          }
        })
        .catch(error => {
          this.classLoader = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
</style>
